import MoreVertIcon from "@mui/icons-material/MoreVert";
import {
  Autocomplete,
  Box,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  FormControl,
  Grid2 as Grid,
  IconButton,
  InputLabel,
  Menu,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography
} from "@mui/material";
import { useEffect, useState } from "react";
import { Translation, useTranslation } from "react-i18next";
import ReactSwal from "../../../helper/AlertHelper";
import { guestIdentityOption, guestStatusOption } from "../../../helper/GlobalValueHelper";
import DebugLogger from "../../DebugLogger";
import UpdaterInfo from "../../UpdaterInfo";
import ValidationTextField from "../../ValidationTextField";
import { MemberApi } from "../../../api/MemberApi";
import { use } from "i18next";
// styles

export default function BaseInfo({
  formik,
  guestDeleteData,
  haveDeletePermission,
  errors,
  requiredFields
}) {
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const [memberList, setMemberList] = useState([]);

  useEffect(() => {
    fetchMemberList();
  }, []);

  const fetchMemberList = async () => {
    try {
      const response = await MemberApi.list(1, -1, []);
      if (response.data) {
        setMemberList(response.data);
      }
    } catch (e) {
      console.log(e);
    }
  };

  const roles = {
    visitor: {
      name: true,
      phoneNumber: true,
      refererMemberId: true,
      companyName: true,
      guestProfession: true,
      recommendedMemberId: true,
      profession: true,
      joinReason: true,
      joinIndex: true,
      chapterName: false,
      substituteMemberId: false,
    },
    observer: {
      name: true,
      phoneNumber: true,
      refererMemberId: true,
      companyName: false,
      guestProfession: true,
      recommendedMemberId: true,
      profession: true,
      joinReason: true,
      joinIndex: false,
      chapterName: true,
      substituteMemberId: false,
    },
    substitute: {
      name: true,
      phoneNumber: true,
      refererMemberId: false,
      companyName: false,
      guestProfession: false,
      recommendedMemberId: true,
      profession: true,
      joinReason: false,
      joinIndex: false,
      chapterName: false,
      substituteMemberId: true,
    },
    member: {
      name: true,
      phoneNumber: true,
      refererMemberId: false,
      companyName: false,
      guestProfession: false,
      recommendedMemberId: false,
      profession: false,
      joinReason: false,
      joinIndex: false,
      chapterName: false,
      substituteMemberId: false,
    }
  };

  const inputTypes = {
    refererMemberId: "autoComplete",
    recommendedMemberId: "autoComplete",
    substituteMemberId: "autoComplete",
    companyName: "text",
    profession: "text",
    guestProfession: "text",
    joinReason: "text",
    joinIndex: "select",
    chapterName: "text",
  };

  const translateField = {
    refererMemberId: "referer_member",
    recommendedMemberId: "recommended_member",
    substituteMemberId: "substitute_member",
    companyName: "company_name",
    profession: "profession",
    guestProfession: "guest_profession",
    joinReason: "join_reason",
    joinIndex: "join_index",
    chapterName: "chapter_name",
  };

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const showDeleteConfirm = () => {
    ReactSwal.fire({
      title: t("error.warning"),
      text: t("error.delete_confirm"),
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: t("layout.ok"),
      cancelButtonText: t("layout.cancel"),
    }).then(result => {
      if (result.isConfirmed) {
        guestDeleteData();
      }
    });
  };

  return (
    <Card variant="outlined">
      <CardHeader
        title={formik.values.id ? t("event.edit_guest") : t("event.new_guest")}
        action={
          guestDeleteData && haveDeletePermission ? (
            <Box>
              <IconButton onClick={handleClick}>
                <MoreVertIcon />
              </IconButton>
              <Menu
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                  "aria-labelledby": "basic-button",
                }}
              >
                <MenuItem onClick={showDeleteConfirm}>
                  <Translation>
                    {t => <Typography color={"error"}>{t("layout.delete")}</Typography>}
                  </Translation>
                </MenuItem>
              </Menu>
            </Box>
          ) : (
            <Box />
          )
        }
      />

      <CardContent>
        <Stack spacing={2}>
          <DebugLogger title={"Guest"} data={formik.values} hidden={true} />
          <DebugLogger title={"Member"} data={memberList} hidden={true} />

          <Grid container spacing={2}>
            <Grid
              size={{
                xs: 6,
              }}
            >
              <FormControl fullWidth>
                <InputLabel required={true}>{t("event.guest_identity")}</InputLabel>
                <Select value={formik.values.identity} label={t("event.guest_identity")} onChange={(event) => {
                  formik.setFieldValue("identity", event.target.value);
                }}>
                  {guestIdentityOption.map(item => (
                    <MenuItem value={item} key={"options_" + item}>
                      {t(`event.guest_identity_${item}`)}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>

            <Grid
              size={{
                xs: 6
              }}
            >
              <ValidationTextField
                name={"name"}
                type={"text"}
                required={true}
                value={formik.values.name || ""}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                title={t("event.guest_name")}
                errorText={formik.errors.name}
                touched={formik.touched.name}
              />
            </Grid>

            <Grid
              size={{
                xs: 6,
              }}
            >
              <ValidationTextField
                name={"phoneNumber"}
                required
                value={formik.values.phoneNumber || ""}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                title={t("event.guest_phone_number")}
                errorText={formik.errors.phoneNumber}
                touched={formik.touched.phoneNumber}
              />
            </Grid>

            <Grid
              size={{
                xs: 6,
              }}
            >
              <FormControl fullWidth>
                <InputLabel required={true}>{t("layout.status")}</InputLabel>
                <Select value={formik.values.status} label={t("layout.status")} onChange={(event) => {
                  formik.setFieldValue("status", event.target.value);
                }}>
                  {guestStatusOption.map(item => (
                    <MenuItem value={item} key={"options_" + item}>
                      {t(`event.guest_status_${item}`)}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>

            {Object.keys(roles[formik.values.identity]).map((key) => {
              if (!roles[formik.values.identity][key]) {
                return null;
              }

              return (
                <Grid
                  key={key}
                  size={{
                    xs: 12,
                    sm: 6,
                  }}
                >
                  {inputTypes[key] === "text" && (
                    <ValidationTextField
                      title={t(`event.${translateField[key]}`)}
                      name={key}
                      value={formik.values[key] || ""}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      touched={errors[key]}
                      errorText={errors[key]}
                      required={requiredFields[formik.values.identity].includes(key)}
                    />
                  )}

                  {inputTypes[key] === "autoComplete" && (
                    <Autocomplete
                      options={memberList}
                      value={memberList.find((member) => member.id === formik.values[key]) || null}
                      getOptionLabel={(option) => `${option.firstName} ${option.lastName}`}
                      renderInput={(params) => (
                        <TextField required {...params} label={t(`event.${translateField[key]}`)} />
                      )}
                      onChange={(event, newValue) => {
                        formik.setFieldValue(key, newValue ? newValue.id : null);
                      }}
                      filterSelectedOptions
                      isOptionEqualToValue={(opt, value) => {
                        return opt.id === (value && value.id);
                      }}
                    />
                  )}

                  {inputTypes[key] === "select" && (
                    <FormControl fullWidth>
                      <InputLabel id="select-label">
                        {t(`event.${translateField[key]}`)}
                      </InputLabel>
                      <Select
                        sx={{
                          height: 56,
                        }}
                        labelId="select-label"
                        id="select"
                        value={formik.values.joinIndex}
                        label={t(`event.${translateField[key]}`)}
                        onChange={(event) =>
                          formik.setFieldValue("joinIndex", event.target.value)
                        }
                        error={errors[key] ? true : false}
                      >
                        <MenuItem value={2}>有興趣加入BNI</MenuItem>
                        <MenuItem value={1}>未知意向</MenuItem>
                        <MenuItem value={0}>沒有興趣加入，純粹支持會友</MenuItem>
                      </Select>

                      {errors[key] && (
                        <Typography variant="caption" color="error">
                          {errors[key]}
                        </Typography>
                      )}
                    </FormControl>
                  )}
                </Grid>
              );
            })}
          </Grid>
          <UpdaterInfo data={formik.values} />
        </Stack>
      </CardContent>

      <CardActions sx={{ justifyContent: "space-between", px: 2 }}></CardActions>
    </Card>
  );
}

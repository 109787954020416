import AuthProvider from "../components/AuthProvider";
import DashboardLayout from "../components/DashboardLayout";
import { getEventPageName } from "../helper/GlobalValueHelper";
import Create from "../views/event/Create";
import Edit from "../views/event/Edit";
import List from "../views/event/List";
import GuestEdit from "../views/event/guest/Edit";

const EventRoute = {
  element: <DashboardLayout />,
  children: [
    {
      path: "/event",
      element: (
        <AuthProvider page={getEventPageName()} action={"view"}>
          <List />
        </AuthProvider>
      ),
    },
    {
      path: "/event/create",
      element: (
        <AuthProvider page={getEventPageName()} action={"create"}>
          <Create />
        </AuthProvider>
      ),
    },
    {
      path: "/event/:id",
      element: (
        <AuthProvider page={getEventPageName()} action={"update"}>
          <Edit />
        </AuthProvider>
      ),
    },
    {
      path: "/event/guest/:id",
      element: (
        <AuthProvider page={getEventPageName()} action={"update"}>
          <GuestEdit />
        </AuthProvider>
      ),
    },
  ],
};

export default EventRoute;

import axios from "../helper/AxiosHelper";

const list = async (page, pageSize, filters) => {
  const query = {
    filters: filters || undefined,
    page: page || 1,
    page_size: pageSize || 10,
    sort: "id.DESC",
  };
  const response = await axios.get(`/event/query?query=${JSON.stringify(query)}&fields=all`);
  const result = response.data.result;

  return {
    data: result.data.map(data => {
      return {
        id: data.id,
        slug: data.slug,
        title: data.title,
        content: data.content,
        createdAt: data.created_at,
        updatedAt: data.updated_at,
        createdBy: data.created_by_info ? data.created_by_info.username : "",
        updatedBy: data.updated_by_info ? data.updated_by_info.username : "",
      };
    }),
    page: result.page,
    pageSize: result.page_size,
    total: result.total,
  };
};

const details = async id => {
  const response = await axios.get(`event/show/${id}?fields=all`);
  const result = response.data.result;
  return {
    id: result.id,
    slug: result.slug,
    title: result.title,
    content: result.content,
    createdAt: result.created_at,
    updatedAt: result.updated_at,
    createdBy: result.created_by_info ? result.created_by_info.username : "",
    updatedBy: result.updated_by_info ? result.updated_by_info.username : "",
  };
};

const create = async input => {
  const data = {
    slug: input.slug,
    title: input.title,
    content: input.content,
  };

  const response = await axios.post(`event/create`, data);

  return response.data.result.insert_id;
};

const update = async (id, input) => {
  const data = {
    slug: input.slug,
    title: input.title,
    content: input.content,
  };

  const response = await axios.post(`event/update/${id}`, data);

  return response.data.success;
};

const deleteData = async id => {
  const response = await axios.post(`event/delete/${id}`);
  return response.data.success;
};

const guestList = async (page, pageSize, filters) => {
  const query = {
    filters: filters || undefined,
    page: page || 1,
    page_size: pageSize || -1,
    sort: "id.DESC",
  };
  const response = await axios.get(`/eventGuest/query?query=${JSON.stringify(query)}&fields=all`);
  const result = response.data.result;

  return {
    data: result.data.map(data => {
      return {
        id: data.id,
        event: data.event_id_info ? data.event_id_info : null,
        name: data.name,
        phoneNumber: data.phone_number,
        identity: data.identity,
        code: data.code,
        status: data.status,
        createdAt: data.created_at,
        updatedAt: data.updated_at,
        createdBy: data.created_by_info ? data.created_by_info.username : "",
        updatedBy: data.updated_by_info ? data.updated_by_info.username : "",
      };
    }),
    page: result.page,
    pageSize: result.page_size,
    total: result.total
  };
};

const guestDetails = async id => {
  const response = await axios.get(`eventGuest/show/${id}?fields=all`);
  const result = response.data.result;
  return {
    id: result.id,
    eventId: result.event_id,
    name: result.name,
    phoneNumber: result.phone_number,
    identity: result.identity,
    code: result.code,
    status: result.status,
    chapterName: result.chapter_name,
    companyName: result.company_name,
    guestProfession: result.guest_profession,
    joinIndex: result.join_index,
    joinReason: result.join_reason,
    profession: result.profession,
    recommendedMemberId: result.recommended_member_id === 0 ? null : result.recommended_member_id,
    refererMemberId: result.referer_member_id === 0 ? null : result.referer_member_id,
    substituteMemberId: result.substitute_member_id === 0 ? null : result.substitute_member_id,
    createdAt: result.created_at,
    updatedAt: result.updated_at,
    createdBy: result.created_by_info ? result.created_by_info.username : "",
    updatedBy: result.updated_by_info ? result.updated_by_info.username : "",
  };
};

const guestUpdate = async (id, input) => {
  const data = {
    event_id: input.eventId,
    name: input.name,
    phone_number: input.phoneNumber.replace(/\s/g, ""),
    identity: input.identity,
    code: input.code,
    status: input.status,
    chapter_name: input.chapterName,
    company_name: input.companyName,
    guest_profession: input.guestProfession,
    join_index: input.joinIndex,
    join_reason: input.joinReason,
    profession: input.profession,
    referer_member_id:
      input.refererMemberId === ""
        ? null
        : input.refererMemberId,
    recommended_member_id:
      input.recommendedMemberId === ""
        ? null
        : input.recommendedMemberId,
    substitute_member_id:
      input.substituteMemberId === ""
        ? null
        : input.substituteMemberId,
  };

  const response = await axios.post(`eventGuest/update/${id}`, data);

  return response.data.success;
};

const guestDeleteData = async id => {
  const response = await axios.post(`eventGuest/delete/${id}`);
  return response.data.success;
};

const attendEvent = async (eventId, code) => {
  const data = {
    event_id: eventId,
    code: code,
  };

  const response = await axios.post("/eventGuest/attend", data);

  return response.data.success;
};

export const EventApi = {
  list,
  details,
  create,
  update,
  deleteData,
  guestList,
  guestDetails,
  guestUpdate,
  guestDeleteData,
  attendEvent,
};
